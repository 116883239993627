<template>
  <div class="container-page">
    <div class="card-investment-russia" v-if="item">
      <p class="title-inner">
        <template v-if="item.isDirect">Прямые</template>
        <template v-else>Накопленные</template>
        инвестиции
        <template v-if="item.isIncomingInRussia"> в Российскую Федерацию</template>
        <template v-else> из Российской Федерации</template>
      </p>

      <div class="card-investment-russia__body">
        <div class="card-investment-russia__body-row">
          <div class="card-investment-russia__container-blocks">
            <div class="card-investment-russia__body-block">
              <p class="card-investment-russia__title">Страна:</p>
              <p class="card-investment-russia__value">
                {{ item.country?.name }}
              </p>
            </div>

            <div class="card-investment-russia__body-block">
              <p class="card-investment-russia__title">На дату:</p>
              <p class="card-investment-russia__value">
                {{ $momentFormat(item.onDate, 'DD MMMM YYYY') }}
              </p>
            </div>

            <div class="card-investment-russia__body-block">
              <p class="card-investment-russia__title">Объем, млн $ США:</p>
              <p class="card-investment-russia__value" v-if="item.value">
                {{ item.value }}
              </p>
              <p class="card-investment-russia__value" v-if="item.isConfidencial">Конфиденциально</p>
            </div>
          </div>

          <div class="table-container mb-16 js-table-arrow" v-if="item?.values?.length > 0">
            <table class="stockTable">
              <thead>
                <tr>
                  <th>Отрасль</th>
                  <th>Объем, млн $ США</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="value in item.values" :key="value.id">
                  <td>{{ value.industry?.name }}</td>
                  <td v-if="value.value">{{ value.value }}</td>
                  <td v-if="value.isConfidential">Конфиденциально</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import API from '../api/index';

  export default {
    name: 'InvestmentRussiaCard',
    data() {
      return {
        id: this.$route.params.id,
        item: {},
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        API.find(this.id).then((response) => {
          this.item = response.data;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-investment-russia {
    &__body {
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      padding: 30px;
      width: 100%;
      font-size: 14px;
      &-row {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px dashed #d7d7d7;
        &:last-of-type {
          padding-bottom: 0px;
          margin-bottom: 0px;
          border-bottom: none;
        }
      }
      &-block {
        margin-bottom: 12px;
      }
    }
    &__title {
      font-weight: 500;
      margin-bottom: 16px;
      font-size: 16px;
      &.main {
        display: flex;
        justify-content: space-between;
        span {
          font-weight: 500;
          display: block;
        }
        .country {
          display: flex;
          align-items: center;
        }
        .flag {
          margin-left: 6px;
          width: 30px;
          height: 20px;
        }
      }
      &-sub {
        margin-bottom: 8px;
      }
    }
    &__value {
      // margin-left: 6px;
      // border: 1px solid black;
      // background-color: #FBFBFB;
      // border: 1px solid #D6E4EC;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      padding: 10px 0px;
    }
    &__list {
      margin-bottom: 16px;
    }
    &__container-blocks {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .card-investment-russia__body-block {
        width: 48%;
      }
    }
  }
  .implemented {
    color: #00b3a9;
  }
  .realize {
    color: #0078c8;
  }
  .perspective {
    color: #003990;
  }

  @media (max-width: 767px) {
    .card-investment-russia__container-blocks {
      .card-investment-russia__body-block {
        width: 100%;
      }
    }
  }
</style>
